@font-face {font-family:"Runescape Chat Font Regular";src:url("./fonts/runescape_chat_font.eot?") format("eot"),url("./fonts/runescape_chat_font.woff") format("woff"),url("./fonts/runescape_chat_font.ttf") format("truetype"),url("./fonts/runescape_chat_font.svg#Runescape-Chat-Font") format("svg");font-weight:normal;font-style:normal;}


@tailwind base;
@tailwind utilities;
@tailwind components;

* {
    font-family: 'Runescape Chat Font Regular';
    @apply text-yellow-300;
    text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    @apply bg-gray-100 bg-opacity-5;
}

::-webkit-scrollbar
{
    width: 4px;
    @apply bg-gray-200/25;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    @apply bg-gray-100 bg-opacity-25;
}

.container {
    background-image: url("/public/img/stoneblock.png");
    background-size: 100% 100%;
    @apply py-10 px-10;
}

input {
    @apply px-4 py-2 text-lg border-2 border-black/75 bg-transparent w-full placeholder-opacity-20 focus:outline-none;
}

textarea {
    @apply px-4 py-2 text-lg border-2 border-black/75 bg-transparent w-full placeholder-opacity-20 focus:outline-none;
}

select {
    @apply px-4 py-2 text-lg border-2 border-black/75 bg-transparent w-full placeholder-opacity-20 focus:outline-none;
}

option {
    @apply bg-black/75;
}
option:hover {
    @apply bg-white;
}

.button {
    background-image: url("/public/img/button.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @apply px-8 py-4 cursor-pointer text-center;
}

.button-image {
    background-image: url("/public/img/button.png");
    background-size: 100% 100%;
}

.button:hover {
    background-image: url("/public/img/button-hover.png");
}

.label[aria-label] {
    z-index: 50;
}

.label[aria-label]:focus:after,
.label[aria-label]:hover:after {
    position: absolute;
    display: block;
    overflow: hidden;
    height: 2em;
    border-radius: .2em;
    padding: 0 .7em;
    margin-top: -3.5rem;
    content: attr(aria-label);
    color: #fff;
    background: #000;
    font-size: 1em;
    line-height: 2em;
    text-align: left;
}

.background {
    background-image: url("/public/img/background.gif");
    background-size: cover;
    background-position: bottom;
}
